<template>
    <LiefengContent>
        <template v-slot:title>网上家园首页</template>
         <template v-slot:contentArea>
             <div class="container-div">
                 <div class="select-div">
                        <span style="padding-top:5px">选择社区：</span>
                        <CascaderCity :orgCode="4401" :resetNum="resetNum" @changeCasader="changeCasader" ></CascaderCity>
                        <Button type="primary" style="margin-left:10px" @click="searchBtn">查询</Button>
                        <Button type="info" style="margin-left:10px" @click="resetBtn">重置</Button>
                 </div>
                 <Card style="width:98%;margin:0 auto;">
                     <div class="top-div">
                        <!-- <div class="item" v-for="(item,index) in topList" :key="index" @click="openRouter(item.router)">
                                <div class="top">
                                    <span class="num">{{item.num}}</span>
                                    <span class="title">{{item.title}}</span>
                                    </div>
                                <div class="bottom">{{item.bottom}}</div>
                        </div> -->
                        <!-- <div class="item-div">
                            <div class="box"> -->
                                <div class="box-div" v-for="(item,index) in topList" :key="index"  @click="openRouter(item.router)">
                                    <img class="img" :src="item.background"/>
                                    <div :class="index == 0?'text-divo' :'text-div'">
                                        <div>
                                          <span class="num">{{item.num | numsFileter}}</span>
                                            <span class="title">{{item.title}}</span>  
                                        </div>
                                        <div>
                                            <span class="num">{{item.numo | numsFileter}}</span>
                                            <span class="title">{{item.titleo}}</span>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            <!-- </div>
                        </div> -->
                    </div>
                 </Card>
<!--                 
                 <Card style="width:98%;margin:20px auto;">
                     <div class="center-div">
                        <div class="equiment-title">居民类型</div>
                        <div class="center-num">
                            <Card class="cardsty" style="background: #2d8cf0;width:400px">
                                <div>
                                    <h1>
                                        <span style="font-size:16px">当前社区居民总数:</span>
                                        <span>{{8500}}</span>
                                    </h1>
                                </div>
                            </Card>
                             <span style="margin-top:15px">
                                            <Tooltip style="margin-left:10px" placement="bottom" >
                                                <span style="padding: 5px 5px 5px 10px;background: #2d8cf0;color: rgb(255, 255, 255);border-radius: 50%;font-weight: bold;">？</span>
                                                <div class="isContent" slot="content">
                                                    <p>当前社区居民总数：指的是在该</p>
                                                    <p>社区的已录入信息且未迁出的</p>
                                                    <p>常住居民信息总数。</p>
                                                    <p>以下统计居民类型占比都是</p>
                                                    <p>以当前社区居民总数为基准，</p>
                                                    <p>各类型人数指的也是当前</p>
                                                    <p>录入了信息的在该社区的常住人口。</p>                                                
                                                 </div> 
                                        </Tooltip>
                                        </span>
                        </div>
                        <div class="center-item">
                            <div class="item" v-for="(item,index) in topList" :key="index">
                                <div class="center">{{item.bottom}}</div>
                                <div class="top">
                                    <span class="num">{{item.num}}</span>
                                 </div>
                                <div class="bottom">
                                    <span>{{item.title}}</span>
                                    <span>{{item.num2}}</span>
                                </div>
                             </div>
                        </div>
                    </div>
                 </Card>

                 <Card style="width:98%;margin:20px auto;">
                     <div class="bottom-div">
                         <div class="left">
                             <div class="top">
                                 <div class="equiment-title">年龄分布</div>
                                 <div>
                                     <VeRing :data="sexData"></VeRing>
                                 </div>
                             </div>
                             <div class="center">
                                 <div class="equiment-title">性别</div>
                                 <div>
                                     <VeRing :data="sexData"></VeRing>
                                 </div>
                             </div>
                             <div class="bottom">
                                 <div class="equiment-title">身份类型情况</div>
                                 <div>
                                     <VeRing :data="sexData"></VeRing>
                                 </div>
                             </div>
                         </div>
                         <div class="right">
                             <div class="top">
                                 <div class="equiment-title">基本数据统计</div>
                                 <div class="top-container">
                                      <div class="item" v-for="(item,index) in topList" :key="index">
                                        <div class="center">{{item.bottom}}</div>
                                        <div class="top">
                                            <span class="num">{{item.num}}</span>
                                        </div>
                                        <div class="bottom">
                                            <span>{{item.title}}</span>
                                            <span>{{item.num2}}</span>
                                        </div>
                                    </div>
                                 </div>
                             </div>
                             <div class="bottom">
                                 <div class="equiment-title">注册底册数据及入驻人员情况</div>
                             </div>
                             
                         </div>
                     </div>
                 </Card> -->
                 
             </div>
        </template>
       
    </LiefengContent>
    
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import CascaderCity from './childrens/CascaderCity'
import VeRing from "v-charts/lib/ring.common";
import iconBg1 from '../../../public/images/icon_bg1.png'
import iconBg2 from '../../../public/images/icon_bg2.png'
import iconBg3 from '../../../public/images/icon_bg3.png'
import iconBg4 from '../../../public/images/icon_bg4.png'
import iconBg5 from '../../../public/images/icon_bg5.png'
import iconBg6 from '../../../public/images/icon_bg6.png'
import iconBg7 from '../../../public/images/icon_bg7.png'
import iconBg8 from '../../../public/images/icon_bg8.png'
import iconBg9 from '../../../public/images/icon_bg9.png'
import iconBg10 from '../../../public/images/icon_bg10.png'
import iconBg11 from '../../../public/images/icon_bg11.png'
import iconBg12 from '../../../public/images/icon_bg12.png'
export default {
    components:{LiefengContent,VeRing,CascaderCity},
    filters:{
        numsFileter(val){
            if(val >= 100){
                return val = '99+'
            }else{
                return val
            }
        }
    },
    data(){
        return{
            topList:[
                {   
                    
                    num:0,
                    title:'条待审核',
                    numo:0,
                    titleo:'条房号待补充',
                    bottom:'网上家园管理',
                    router:'',
                    background:iconBg1
                },
                {
                    num:0,
                    title:'条待回复',
                    bottom:'居民邮箱管理',
                    router:`/emailindex?isLineHome=true&menuId=${this.$route.query.menuId}`,
                    background:iconBg2
                },
                {
                    num:'',
                    title:'',
                    bottom:'群发消息',
                    router:'/emailgroup?isLineHome=true',
                    background:iconBg3
                },
                {
                    num:'',
                    title:'',
                    bottom:'民情档案',
                    router:'/linehomeindex?isLineHome=true&page=true',
                    background:iconBg4
                },
                {
                    num:'',
                    title:'',
                    bottom:'居民底册管理',
                    router:'/basebookindex?isLineHome=true',
                    background:iconBg5
                },
                {
                    num:'',
                    title:'',
                    bottom:'辖区单位管理',
                    router:'/shopmanagementindex?isLineHome=true',
                    background:iconBg6
                },
                {
                    num:'',
                    title:'',
                    bottom:'社区架构管理',
                    router:'/area?isLineHome=true',
                    background:iconBg7
                },
                {
                    num:'',
                    title:'',
                    bottom:'楼组管理',
                    router:'/leaderindex?isLineHome=true',
                    background:iconBg8
                },
                {
                    num:'',
                    title:'',
                    bottom:'网格管理',
                    router:'/gridindex?isLineHome=true',
                    background:iconBg9
                },
                {
                    num:'',
                    title:'',
                    bottom:'社区动员组织管理',
                    router:'/mobilizationindex?isLineHome=true',
                    background:iconBg10
                },
                 {
                    num:'',
                    title:'',
                    bottom:'社区动员体系',
                    router:'/mobilizationsystem?isLineHome=true',
                    background:iconBg11
                },
                 {
                    num:'',
                    title:'',
                    bottom:'沟通组管理',
                    router:'/groupindex?isLineHome=true',
                    background:iconBg12
                },
            ],
            // 性别分布数据、
            sexData: {
                columns: ["性别", "人数"],
                rows: [
                { 性别: "男", 人数: "1" },
                { 性别: "女", 人数: "2" },
                { 性别: "未知", 人数: "3" }
                ]
            },

            resetNum:0,
            cascaderList:[]
            
        }
    },
    created(){
       
       
    },
    methods:{
        // 重置按钮
        resetBtn(){
            this.resetNum++
            this.cascaderList = []
        },
        // 查询按钮
        searchBtn(){
            console.log('查询this.$Message.destroy();',this.cascaderList);
            if(this.cascaderList.length != 3){
                this.$Message.warning({
                    content:'请选择到社区再进行查询',
                    background:true
                })
                return
            }
            this.getNum()
            this.getEmailNum()
            this.gethandleNum()
        },
        // 跳转界面
        openRouter(router){
            if(router == '/linehomeindex?isLineHome=true&page=true'){
                this.$Message.warning({
                    content:'功能升级中，敬请期待',
                    background:true
                })
                return 
            }
            if(router == ''){
                this.$router.push(`/linehomemanage?name=linehomeindex&communityCode=${this.cascaderList[this.cascaderList.length - 1]}&isLineHome=true`)
                return
            }
            this.$router.push(router)
        },
        changeCasader(val){
            this.cascaderList = val
            if(this.resetNum == 0 && this.cascaderList.length == 3){
                this.getNum()
                this.gethandleNum()
                this.getEmailNum()
            }
        },
        // 获取网上家园管理待审核人数
        getNum(){
            this.$Message.loading({
                content: "数据正在加载中",
                duration: 0
            });
            this.$get('/syaa/pc/sy/user/online/home/getFamilyMemberCount',{
                communityCode:this.cascaderList[this.cascaderList.length - 1],
                status:1,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                inviterFlag:1
            }).then(res => {
                this.$Message.destroy();
                if(res.code == 200){
                    this.topList[0].num = res.data
                }
            })
            
        },
        gethandleNum(){
            this.$get('/syaa/pc/sy/user/online/home/selectFamilyMemberReplenishRooNo',{
                operCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode:this.cascaderList[this.cascaderList.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if(res.code == 200 )
                this.topList[0].numo = res.data
            })
        },
        getEmailNum(){
            this.$get("/sendmsg/pc/chat/communityMessage/queryReplyLatestCount", {
                communityCode:this.cascaderList[this.cascaderList.length - 1],
                status: 1,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId
            }).then(res=>{
                 if(res.code == 200){
                    this.topList[1].num = res.data
                }
            })
        }
 
    }
    
}
</script>

<style lang="less" scoped>
 //编写css
.container-div{
    .select-div{
        margin-bottom: 20px;
        margin-left: 20px;
        display: flex;
    }
    .top-div{
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 16%);
        justify-content: center;
        .box-div{
                    margin:12px;
                    position: relative;
                    width: 240px;
                    cursor: pointer;
                    .img{
                        width: 80%;
                    }
                    .text-div{
                        position: absolute;
                        bottom: 10px ;
                        width: 100%;
                        padding-left:30px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-wrap: normal;
                        height: 50px;
                        line-height: 50px;
                        .num{
                            font-size: 36px;
                            color: rgba(249,224,62);
                        }
                        .title{
                            margin-left: 10px;
                            font-size: 16px;
                            color: #ffffff;
                        }

                    }
                    .text-divo{
                        position: absolute;
                        bottom: 19px ;
                        width: 100%;
                        padding-left:30px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-wrap: normal;
                        height: 50px;
                        line-height: 25px;
                        .num{
                            font-size: 18px;
                            color: rgba(249,224,62);
                            font-weight: 700;
                        }
                        .title{
                            margin-left: 10px;
                            font-size: 16px;
                            color: #ffffff;
                        }

                    }
                }
        // .item{
        //     cursor: pointer;
        //     width: 180px;
        //     margin:0 20px 20px 0;
        //     border: 1px solid #cccccc;
        //     text-align: center;
        //     height: 110px;
        //     .top{ 
        //         padding: 20px;
        //         .num{
        //             color: red;
        //             font-weight: bold;
        //             font-size: 22px;
        //         }
        //         .title{
        //             font-size: 16px;
        //             color:#666666 ;
        //         }
        //     }
        //     .bottom{
        //         color: black;
        //         font-size: 20px;
        //         padding-bottom:5px ;
        //     }
        // }
        .item-div{
            margin: 0 auto;
            justify-content: center;
            cursor: pointer;
            width: 80%;
            padding: 0 5%;
            border: 1px solid green;
            .box{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                border: 1px solid yellow;
                margin:  0 auto;
                justify-content: center;
                
            }
            
        }
    }
    .center-div{
        width: 100%;
        .center-num{
            color: white;
            display: flex;
        }
        .center-item{
            width: 100%;
            display: flex;
            margin-top: 20px;
            flex-wrap: wrap;
             .item{
                    cursor: pointer;
                    background:  #2d8cf0;
                    color: #ffffff;
                    border-radius: 5px;
                    width: 100px;
                    margin:0 20px 20px 0;
                    border: 1px solid #cccccc;
                    text-align: center;
                    .center{
                        margin-top: 10px;
                    }
                    .top{ 
                        padding: 10px 20px;
                        .num{
                            font-weight: bold;
                            font-size: 22px;
                        }
                        .title{
                            font-size: 16px;
                            color:#666666 ;
                        }
                    }
                    .bottom{
                        margin-bottom: 10px;
                    }
                }
        }
    }
    .bottom-div{
        display: flex;
        justify-content: flex-start;
        // height: 700px;
        .left{
            margin-right: 20px;
            width: 35%;
            div{
                // height: 30%;
                margin-right: 10px 0;
            }
        }
        .right{
            width: 65%;
            .top{
                .top-container{
                     width: 100%;
                display: flex;
                margin-top: 20px;
                flex-wrap: wrap;
                .item{
                        cursor: pointer;
                        background:  #2d8cf0;
                        color: #ffffff;
                        border-radius: 5px;
                        width: 100px;
                        margin:0 20px 20px 0;
                        border: 1px solid #cccccc;
                        text-align: center;
                        .center{
                            margin-top: 10px;
                        }
                        .top{ 
                            padding: 10px 20px;
                            .num{
                                font-weight: bold;
                                font-size: 22px;
                            }
                            .title{
                                font-size: 16px;
                                color:#666666 ;
                            }
                        }
                        .bottom{
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
     .equiment-title{
            position: relative;
            padding-left: 20px;
            margin:10px 0;
            font-size: 16px;
            font-weight: bold;
            &::after{
                position: absolute;
                content:'';
                top: 0;
                left: 0;
                width: 5px;
                height: 20px;
                background-color: #2db7f5;
            }
        }
}

</style>